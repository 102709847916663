import Section, {Padding} from "../templates/Section";
import {Color} from "../../enums/Color";
import React, {useEffect} from "react";
import Text, {TextType} from "../atoms/Text";

function ThankYou(){

    useEffect(() => {
        setTimeout(() => {
            window.location.replace('/');
        }, 5000);
    }, []);

    return (
        <Section padding={Padding.M} className='text-center my-auto bg-red flex h-[55vh]'>
                <Text type={TextType.H2} color={Color.White} className='my-auto mx-auto'>
                    {'Gracias, en breve nos pondremos en contacto contigo.'}
                </Text>
        </Section>
    )
}

export default ThankYou;