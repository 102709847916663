export function sendWhatsappEvent(url) {
    let callback = function () {
        if (typeof(url) != 'undefined') {
            url = window.location;
        }
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-10794164477',
        'event_callback': callback
    });
    return false;
    
}