import MultiProductCardIPictureItem from "../molecules/MultiProductCardIPictureItem";
import React from "react";
import Button from "../atoms/Button";
import {OrganismComponent} from "../../enums/OrganismComponent";
import {sendWhatsappMessageCustomText} from "../../services/whatsAppService";

interface MultiProductCardProps {
    imagesMapArray: [string, string][];// [Nombre de la imagen, Descripción] // [Nombre de la imagen, Descripción]
    bagArticle?: String; // Articulo del objeto
}

const MultiProductCard = (props: MultiProductCardProps) => {

    const [selectedBagId, setSelectedBagId] = React.useState('');

    const handleBagSelection = (bagId: string) => {
        setSelectedBagId(bagId);
    }


    return (<div className="py-[1.375rem] rounded-2xl border-[#A0B9D9] border-2 bg-white relative" style={{'backgroundImage' : 'url(/images/bags/bg-bags.png)'}}>
        {props.bagArticle?<span className="absolute p-2.5 shadow-md rounded text-[#04009E] text-lg font-semibold right-0 top-0 mt-2.5 mr-2.5">art. {props.bagArticle?props.bagArticle:''}</span>:''}
        <MultiProductCardIPictureItem callback={handleBagSelection} imagesMapArray={props.imagesMapArray}/>
        <Button text="Ver Mas" onClick={() => sendWhatsappMessageCustomText(`Hola vengo desde la web y te hablo por la mochíla códio:${selectedBagId}`)}/>
    </div>);

};

MultiProductCard.componentName = OrganismComponent.MultiProductCard;

export default MultiProductCard;