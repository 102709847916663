import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, {useState} from "react";
import Slider from 'react-slick';
import {childrenToArray} from "../../helpers/GlobalHelper";
import { ReactComponent as SliderRightArrow } from '../../assets/SliderRightArrow.svg';
import { ReactComponent as SliderLeftArrow } from '../../assets/SliderLeftArrow.svg';
import '../../css/react-slider.css';


interface BrandSliderProps {
    children: React.ReactNode[] | React.ReactNode
}

function BrandSlider(props: BrandSliderProps) {

    const [currentSlide, setCurrentSlide] = useState(0);

    const ArrowRight = (props:any) => (
        <button
            {...props} className="slick-next">
                <SliderRightArrow height={'40px'}/>
        </button>
    );
    const ArrowLeft = (props:any) => (
        <button
            {...props} className="slick-prev">
                <SliderLeftArrow height={'40px'}/>
        </button>
    );

    const settings = {
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        nextArrow: <ArrowRight/>,
        prevArrow: <ArrowLeft/>
    };

    const getImages = () => {
        return childrenToArray(props.children).map((child, i) => {
            return child;
        })
    }

    return (
            <Slider className="w-full" {...settings}>
                {getImages()}
            </Slider>
    );

}

export default BrandSlider;