import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, {useEffect, useState} from "react";
import Slider from 'react-slick';

import { ReactComponent as SliderRightArrow } from '../../assets/bags-arrow-right.svg';
import { ReactComponent as SliderLeftArrow } from '../../assets/bags-arrow-left.svg';

const imageRootPath = '/images/bags/'

interface MultiProductCardIPictureItemProps {
    imagesMapArray: [string, string][]; // [Nombre de la imagen, Descripción]
    callback: any;
}

function MultiProductCardIPictureItem(props: MultiProductCardIPictureItemProps) {

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        props.callback(props.imagesMapArray[currentSlide][1]);
    }, [currentSlide]);

    const ArrowRight = (props:any) => (
        <button
            {...props} className="slick-next-bags">
                <SliderRightArrow height={'40px'}/>
        </button>
    );
    const ArrowLeft = (props:any) => (
        <button
            {...props} className="slick-prev-bags">
                <SliderLeftArrow height={'40px'}/>
        </button>
    );


    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        arrows: props.imagesMapArray.length > 1,
        nextArrow: <ArrowRight/>,
        prevArrow: <ArrowLeft/>
    };

    const getImages = () => {
        return props.imagesMapArray.map((image) => {
            return (
                <img className="!w-[13.5rem]" key={image[0]} src={imageRootPath + image[0]} alt={image[1]}/>
            )
        })
    }

    return (
        <div className="relative">
            <Slider className="w-full" {...settings}>
                {getImages()}
            </Slider>
        </div>
    );

}

export default MultiProductCardIPictureItem;