
interface ContactLineProps {
    children: React.ReactNode[] | React.ReactNode;
}

const ContactLine = (props: ContactLineProps) => {

    return (
        <div className="flex space-x-2.5 w-max">
            {props.children}
        </div>
    )

}

export default ContactLine;