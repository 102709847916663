import Text, {TextType} from "../atoms/Text";
import {Color} from "../../enums/Color";
import {MoleculeComponent} from "../../enums/MoleculeComponent";

export enum TextAlignment {
    Center = 'center',
    Right = 'right'
}

interface HeroSliderItemProps {
    title: string;
    textAlignment?: TextAlignment;
    imagePathName: string;
    textColor: Color;
}

const imageRootPath = '/images/hero/'

const HeroSliderItem = (props: HeroSliderItemProps) => {
    return (
        <div className="relative flex bg-cover bg-no-repeat block h-[16.35rem] md:h-[43.75rem]" style={{backgroundImage: `url(${imageRootPath + props.imagePathName})`, backgroundSize: '100% 100%'}}>
            <div className={"absolute top-[40%] " + (props.textAlignment && props.textAlignment === TextAlignment.Right? "right-[10%]": 'right-[50%] translate-x-1/2')}>
                <Text type={TextType.H1}
                      color={props.textColor}>
                    {props.title}
                </Text>
            </div>
        </div>
    )
}

HeroSliderItem.componentName = MoleculeComponent.HeroSliderItem;
export default HeroSliderItem;