import React from 'react';
import './App.css';
import './css/react-slider.css';
import GeneralLink, {LinkType} from "./components/atoms/GeneralLink";
import Navbar from "./components/organisms/Navbar";
import Footer from "./components/organisms/Footer";
import WhatsAppFloatingButton from "./components/atoms/WhatsAppFloatingButton";
import CustomRouter from "./components/CustomRouter";


function App() {

    const [name, setName] = React.useState('');
    const [email, setEmail ] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState('');

    return (


       <div className="App">


            <Navbar logoPath='/images/logo/WassaThin.png' mobileLogoPath='/images/logo/Logowassa.png'>
                <GeneralLink type={LinkType.General} isExternal={false} path='/#us'>NOSOTROS</GeneralLink>
                <GeneralLink type={LinkType.General} isExternal={false} path='/#kits'>NUESTROS KITS</GeneralLink>
                <GeneralLink type={LinkType.General} isExternal={false} path='/#bags'>MOCHILAS</GeneralLink>
                <GeneralLink type={LinkType.General} isExternal={false} path='/#contact'>CONTACTANOS</GeneralLink>
            </Navbar>

            <CustomRouter/>

            <Footer imagePath='/images/logo/Logowassa.png'/>

            <WhatsAppFloatingButton/>

        </div>
    );
}

export default App;
