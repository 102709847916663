import Icon, {IconType} from "./Icon";
import {sendWhatsappMessageCustomText} from "../../services/whatsAppService";


const WhatsAppFloatingButton = () => {
    return (

            <div className="flex fixed z-40 bottom-4 right-4 w-[4rem] h-[4rem]
            rounded-full justify-center items-center bg-[#5FD669] cursor-pointer"
            onClick={() => {sendWhatsappMessageCustomText("hola, vengo de tu sitio web y me interesaría más información.")}}>
                <Icon iconType={IconType.Whatsapp}/>
            </div>
    )

}


export default WhatsAppFloatingButton;