interface ContactFormProps {
    children: React.ReactNode[] | React.ReactNode;
}

const ContactForm = (props: ContactFormProps) => {

    return (
        <div className=" my-[3.125rem] lg:max-w-[42.313rem] mx-auto space-y-[1.25rem]">
            {props.children}
        </div>
    );
}

export default ContactForm;