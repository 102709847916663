import React from "react";
import {getArrayChildByType} from "../../helpers/GlobalHelper";
import {OrganismComponent} from "../../enums/OrganismComponent";
import MultiProductBoxCategoryExpandingCard from "../organisms/MultiProductBoxCategoryExpandingCard";

interface MultiProductBoxCategoryExpandingCardContainerProps {
    children: React.ReactNode[] | React.ReactNode
}

const MultiProductBoxCategoryExpandingCardContainer = (props: MultiProductBoxCategoryExpandingCardContainerProps) => {

    const cards = getArrayChildByType(OrganismComponent.MultiProductBoxCategoryExpandingCard, props.children);

    return (
        <div className="inline-grid md:grid-cols-2 grid-cols-1 w-full gap-[3.125rem]">
            {cards.map((card: any, index: number) => {
                return <MultiProductBoxCategoryExpandingCard key={index} {...card.props}/>
            })}
        </div>
    )

}

export default MultiProductBoxCategoryExpandingCardContainer;