import {useEffect, useState} from "react";

export enum Size{
    sm = 640,
    md = 768
}

export  function useIsWindowSizeAbove(size: number) {

    const [isWindowSizeBellow, setIsWindowSizeBellow] = useState(false);
    const handleWindowSize = () => {
        setIsWindowSizeBellow(window.innerWidth > size);
    }

    useEffect(() => {
        handleWindowSize();
        window.addEventListener('resize', handleWindowSize);

        return () => {
            window.removeEventListener('resize', handleWindowSize);
        }

    });
    return isWindowSizeBellow;
}