import * as emailjs from "@emailjs/browser";

const PUBLIC_KEY = "ctsIIpw4-EhsCd2DW";
const SERVICE_ID = "service_pc3kx8m";
const TEMPLATE_ID = "template_x2u13eu";

emailjs.init(PUBLIC_KEY);

export function sendEmail(name, email, phone, message) {
    return emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams(name, email, phone, message));

}

function templateParams(name, email, phone, message) {
    return {
        from_name: name,
        from_email: email,
        from_phone: phone,
        message: message,
    };
}