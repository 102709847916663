interface Banner1Props {
    text: string;
    imagePathName: string;
    isHiddenOnMobile?:boolean;
}

const Banner1 = (props: Banner1Props) => {

    let BannerClassname='';
    BannerClassname = BannerClassname.concat(
        props.isHiddenOnMobile?'hidden ':'block h-[13.125rem] '
        ,"w-full md:pt-[29.16%]  bg-no-repeat bg-center md:block bg-contain"
    );

    return (
        <div className={BannerClassname}
            style={{'backgroundImage':'url('+props.imagePathName+')'}}>
            <div className="w-full h-full bg-cover bg-center"/>
        </div>
    )

}

export default Banner1;