import React from "react";

interface ButtonProps {
    text: string;
    onClick: () => void;
    className?: string;
}

// w-[19.375rem] h-[4rem]

const Button = (props: ButtonProps) => {


    return (<button onClick={() => props.onClick()} className={"md:h-[3.75rem] h-[2.8rem] bg-button-blue rounded-2xl\n" +
        "    transition-transform transform duration-150 hover:scale-105 " + props.className}>
        <div className="md:px-[3.4rem] px-[1.15rem]">
            <p className="md:text-[1.5rem]/[1.96rem] text-[1rem]/[1.25rem] font-dm-sans-medium text-white">{props.text}</p>
        </div>
    </button>)

}

export default Button;