interface ContactInputProps {
    children: string;
    onChangeFunction:any;
    isRequiered?: boolean;
    isBig?: boolean;
    value?: string;
}

const ContactInput = (props: ContactInputProps) => {

    const handleOnChange = (event:any) => {
        props.onChangeFunction(event.target.value);
    }

    let inputClasses = '';
    inputClasses = inputClasses.concat(
        props.isBig?'min-h-[6.25rem] lg:min-h-[10.813rem]':'min-h-[2.5rem] lg:min-h-[4.313rem]'
        , " border-solid border border-blue "
    );

    return (
        <div className=" flex flex-col text-left space-y-2.5">
            <label className=" text-s lg:text-l font-medium font-dm-sans " >{props.children}{props.isRequiered?<span className="text-red">*</span>:''}</label>
            
            {props.isBig?
            <textarea onChange={handleOnChange} value={props.value}  className={inputClasses} ></textarea>
            :<input onChange={handleOnChange} value={props.value} type="text" className={inputClasses}/>
            }
            
        </div>
    );
}

export default ContactInput;