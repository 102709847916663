import {AtomComponent} from "../../enums/AtomComponent";

enum LinkType {
    General = "General",
    Contact = "Contact"
}

interface GeneralLinkProps {
    type: LinkType;
    isExternal: boolean;
    path: string;
    children: string;
    className?: string;
    onClickFunction?: () => void;
}

function GeneralLink(props: GeneralLinkProps) {
    let GeneralLinkClasses='';
    GeneralLinkClasses = GeneralLinkClasses.concat(
        props.type === LinkType.General? ' text-m-20 text-white font-bold uppercase whitespace-nowrap font-dm-sans ': 'text-s lg:text-l font-medium font-dm-sans text-black'
        ,props.className?props.className:''
    );
    return (
        <a href={props.path} target={props.isExternal?'blank_':''} className={GeneralLinkClasses} onClick={props.onClickFunction}>{props.children}</a>
    );
    }

GeneralLink.componentName = AtomComponent.GeneralLink;
export {LinkType};
export default GeneralLink;