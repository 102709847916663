import Text, {TextType} from "../atoms/Text";
import {Color} from "../../enums/Color";
import {getArrayChildByType} from "../../helpers/GlobalHelper";
import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import MultiProductBoxCategoryExpandingItem from "../molecules/MultiProductBoxCategoryExpandingItem";
import {OrganismComponent} from "../../enums/OrganismComponent";
import {MoleculeComponent} from "../../enums/MoleculeComponent";

interface MultiProductBoxCategoryExpandingCardProps {
    children: React.ReactNode[] | React.ReactNode;
    title: string;
    imagePathName: string;
    key?: number;
}

const imageRootPath = '/images/multiProducts/';

let StyledImage = styled.img`
  width: -webkit-fill-available;
  width: 100%;
`;

let StyledDiv = styled.div`
  position: relative;
  transition: bottom 2s ease 0sr;
  -webkit-transition: bottom 2s ease 0s;
  -moz-transition: bottom 2s ease 0s;
  -o-transition: bottom 2s ease 0s;
  bottom: 0px;
`;

const MultiProductBoxCategoryExpandingCard = (props: MultiProductBoxCategoryExpandingCardProps) => {

    const [forceUpdate, setForceUpdate] = useState(0);


    const refTitleDiv = useRef<HTMLDivElement | null>(null);
    const refItemsDiv = useRef<HTMLDivElement | null>(null);

    const [verticalDifference, setVerticalDifference] = React.useState(0);

    const [isExpanded, setIsExpanded] = React.useState(false);
    const handleSetIsExpanded = (_isExpanded: boolean): void => {
        setIsExpanded(_isExpanded);
    }

    const handleMoveStyledDiv = (pxToMove: number) => {
        if (!isExpanded) {
            StyledDiv = styled(StyledDiv)`bottom: ${pxToMove}px;`
            StyledImage =styled(StyledImage)`opacity: 0;`
        } else {
            StyledDiv = styled(StyledDiv)`bottom: ${0}px;`
            StyledImage =styled(StyledImage)`opacity: 1;`
            
        }
    }

    useEffect(() => {
        handleMoveStyledDiv(verticalDifference);
        setForceUpdate(forceUpdate + 1);
    }, [isExpanded])

    //Fuerzo un update de StyledImage para que se calule la diferencia de altura entre el titulo y los items
    useEffect(() => {
        StyledImage = styled(StyledImage)``;
    }, []);

    useEffect(() => {
        if (refItemsDiv.current && refTitleDiv.current) {
            const rect1 = refTitleDiv.current.getBoundingClientRect();
            const rect2 = refItemsDiv.current.getBoundingClientRect();
            setVerticalDifference(Math.abs(rect1.bottom - rect2.top));
        }
    }, [StyledImage]);


    return (
        <div key={props.key} className="w-full h-auto rounded-tl-2xl rounded-tr-2xl">
            <div ref={refTitleDiv} className="bg-[#F6F6F6] rounded-t-2xl pt-6">
                <Text className="text-center" type={TextType.H2} color={Color.Blue2}>{props.title}</Text>
            </div>
            <div>
                <StyledImage key={props.key} className="flex ml-auto mr-auto "
                             src={imageRootPath + props.imagePathName}/>
            </div>
            <StyledDiv ref={refItemsDiv}>
                {getArrayChildByType(MoleculeComponent.MultiProductBoxCategoryExpandingItem, props.children).map((child) => {
                    return MultiProductBoxCategoryExpandingItem({...child.props, callback: handleSetIsExpanded});
                })}
            </StyledDiv>
        </div>
    )


}

MultiProductBoxCategoryExpandingCard.componentName = OrganismComponent.MultiProductBoxCategoryExpandingCard
export default MultiProductBoxCategoryExpandingCard;