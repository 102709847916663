import React, {ReactNode, useState} from 'react';
import {useIsWindowSizeBellow, modifiedLinks} from "../../helpers/GlobalHelper";

//Menu Icons
import { ReactComponent as CloseMenu } from '../../assets/CloseMenu.svg';
import { ReactComponent as OpenMenu } from '../../assets/OpenMenu.svg';

import {ScreenSize} from "../../enums/ScreenSize";

interface NavBarProps {
    logoPath : string;
    mobileLogoPath? : string;
    children: ReactNode;
}

function NavBar(props: NavBarProps) {

    const isWindowSizeBelow = useIsWindowSizeBellow(ScreenSize.Tablet);

    const [mobileMenu, setMobileMenu] = useState(false);

    const ShowMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    }

    const closeMenuOnMobile = ()=>{
        setMobileMenu(false);
    }
    const checkLogo =()=>{
        if (props.mobileLogoPath) {
            return isWindowSizeBelow?props.mobileLogoPath:props.logoPath;
        }
        else{
            return props.logoPath;
        }
    }

    return (
        <header className='w-full'>
            <nav className='w-full xl:pr-[8.75rem] px-[1.875rem] py-[1.125rem] lg:py-6 flex flex-row justify-between items-center bg-red'>
                <div className='text-white mr-auto'> <a href='#home' className={'mr-auto '}>
                    <img src={checkLogo()} alt="" className='h-[100px] w-[100px] lg:h-[61.38px] lg:w-[180px] mr-auto'/></a></div>
                <div className='text-white flex lg:hidden ml-auto' onClick={ShowMobileMenu}>
                    {
                        mobileMenu ?
                        <CloseMenu fill='#FFFFFF' stroke='#FFFFFF' height='32px' width='32px' className='ml-auto'/>
                        :<OpenMenu fill='#FFFFFF' stroke='#FFFFFF' height='32px' width='32px' className='ml-auto'/>
                    }
                </div>
                <ul className='hidden lg:flex flex-row items-center space-x-[4.375rem] xl:space-x-[9.271rem] px-[20px]'>{props.children}</ul>
            </nav>
            {mobileMenu && isWindowSizeBelow?<ul className='flex flex-col p-[1.875rem] space-y-5 bg-red h-screen text-left'>{modifiedLinks(closeMenuOnMobile,props.children)}</ul>:''}
        </header>

    );
}

export default NavBar;