import {ReactComponent as Whatsapp} from "../../assets/icons/whatsappWhite.svg";
import {ReactComponent as Email} from "../../assets/icons/email.svg";
import {ReactComponent as ArrowRight} from "../../assets/icons/arrowRigth.svg";
import {Size, useIsWindowSizeAbove} from "../../hooks/useIsWindowSizeAbove";
import {AtomComponent} from "../../enums/AtomComponent";
import Text from "./Text";


export enum IconType {
    Whatsapp = "Whatsapp",
    Email = "Email",
    ArrowRight = "ArrowRight"
}

interface IconProps {
    path?: string;
    iconType: IconType;
}

const Icon = (props: IconProps) : JSX.Element => {

    const isSm = useIsWindowSizeAbove(Size.sm);
    const getIcon = () => {
        switch (props.iconType) {
            case IconType.Whatsapp:
                return <Whatsapp height={isSm ? "2.25rem" : "2.5rem"} width={isSm ? "2.25rem" : "2.5rem"}/>
            case IconType.Email:
                return <Email height={isSm ? "1.25rem" : "2.5rem"} width={isSm ? "1.25rem" : "2.5rem"}/>
            case IconType.ArrowRight:
                return <ArrowRight height={isSm ? "1.25rem" : "2.5rem"} width={isSm ? "1.25rem" : "2.5rem"}/>
            default:
                return <ArrowRight height={isSm ? "1.25rem" : "2.5rem"} width={isSm ? "1.25rem" : "2.5rem"}/>
        }
    }

    return (
        getIcon()
    )
}

Icon.componentName = AtomComponent.Icon;

export default Icon;
