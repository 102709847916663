import React, {useEffect, useState} from "react";
import {MoleculeComponent} from "../../enums/MoleculeComponent";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import Text, {TextType} from "../atoms/Text";
import {Color} from "../../enums/Color";
import {childrenToArray, getChildByType} from "../../helpers/GlobalHelper";
import {Size, useIsWindowSizeAbove} from "../../hooks/useIsWindowSizeAbove";
import {AtomComponent} from "../../enums/AtomComponent";

export enum BgType {
    Red1 = "Red1",
    Red2 = "Red2",
    Red3 = "Red3",
    Blue1 = "Blue1",
    Blue2 = "Blue2",
    Blue3 = "Blue3",
    Grey1 = "Grey1"
}

interface MultiProductBoxCategoryExpandingItemProps {
    title: string;
    children: React.ReactNode[] | React.ReactNode;
    bgType: BgType;
    callback?: (isExpanded: boolean) => void;
}

const MultiProductBoxCategoryExpandingItem = (props: MultiProductBoxCategoryExpandingItemProps) => {

    const childrenArray = childrenToArray(props.children);

    const isWindowAboveSm = useIsWindowSizeAbove(Size.md);

        const defaultClasses = "flex w-full cursor-pointer px-[1.25rem] md:px-[1.6rem]";

    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    }
    const handleOutsideClick = () => {
        return null;
/*
        setIsExpanded(false);
*/
    }
    useEffect(() => {
        if (props.callback && !isExpanded)
            props.callback(true);
        if (props.callback && isExpanded)
            props.callback(false);
    }, [isExpanded])


    const ref = useOutsideClick(() => handleOutsideClick());

    const getBgColor = () => {
        switch (props.bgType) {
            case BgType.Red1:
                return " bg-red1 ";
            case BgType.Red2:
                return " bg-red2 ";
            case BgType.Red3:
                return " bg-red3 ";
            case BgType.Blue1:
                return " bg-blue1 ";
            case BgType.Blue2:
                return " bg-blue2 ";
            case BgType.Blue3:
                return " bg-blue3 ";
            case BgType.Grey1:
                return " bg-grey1 ";
        }
    }

    const getExpandedContainerBackground = () => {
        var expresionRegular = /red/gi;
        return props.bgType.match(expresionRegular)? " flex-col bg-red4 space-y-5 pb-5" : " flex-col bg-blue2 space-y-5 pb-5";
    }

    const titleContainer = () => {
        return (
            <div key={props.title} ref={ref} className={defaultClasses + getBgColor() + "items-center"}
                 onClick={handleExpand}>
                <p className="flex mr-auto text-m-20 md:text-l font-dm-sans font-medium  p-2.5">{props.title}</p>
                <p className="flex ml-auto text-m-20 md:text-l font-dm-sans font-medium p-2.5">{isExpanded ? "˅" : ">"}</p>
            </div>
        );
    }

    const contentContainer = () => {
        return (
            <div className={defaultClasses.concat(getExpandedContainerBackground())}>
                {childrenArray.map((child, i) => {
                    if (child.componentName === AtomComponent.Text){
                        return <Text className={"mt-2 mb-2"} type={isWindowAboveSm ? TextType.L : TextType.M_20}
                                     color={Color.Black}
                                     children={child}
                        />
                    }
                    else
                        return <div className={"mt-2 mb-2"}>{child}</div>
                })}
            </div>
        )
    }

    return (
        <>
            {titleContainer()}
            {isExpanded ? contentContainer() : null}
        </>
    )

}

MultiProductBoxCategoryExpandingItem.componentName = MoleculeComponent.MultiProductBoxCategoryExpandingItem;

export default MultiProductBoxCategoryExpandingItem;