import {ScreenSize} from "../../enums/ScreenSize";
import {useIsWindowSizeBellow} from "../../helpers/GlobalHelper";
//Contact Icons
import { ReactComponent as Whatsapp } from '../../assets/contact/whatsapp.svg';
import { ReactComponent as Mail } from '../../assets/contact/email.svg';
import {AtomComponent} from "../../enums/AtomComponent";

enum IconType {
    Whastapp= "Whastapp",
    Mail = "Mail",
}

interface IconProps {
    color: string;
    url?: string;
    type: IconType;
    isExternal?: boolean;

}

function GeneralIcon(props: IconProps) {

    let IconSize ="0px";
    IconSize = useIsWindowSizeBellow(ScreenSize.Tablet)?"20px":"35px";

    const getIcon=()=>{
        switch (props.type) {
            case IconType.Whastapp:
                 return(<Whatsapp fill={props.color} stroke={props.color} height={IconSize} width={IconSize}/>)
            break;
            case IconType.Mail:
                return(<Mail fill={props.color} stroke={props.color} height={IconSize} width={IconSize}/>)
            break;
            default:
                return(<Whatsapp fill={props.color} stroke={props.color} height={IconSize} width={IconSize}/>)
            break;
        }
    }

    return (
    <a href={props.url} target={props.isExternal?'blank_':''} className="flex justify-items-center">
        {getIcon()} 
    </a>         
  );
}

GeneralIcon.componentName = AtomComponent.GeneralIcon

export {IconType};
export default GeneralIcon;