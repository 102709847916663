interface BrandSliderItemProps {
    imagePathName: string;
}

const imageRootPath = '/images/brands/'

const BrandSliderItem = (props: BrandSliderItemProps) => {
    return (
        <div className="w-[8rem] md:w-[10rem] flex ml-auto mr-auto">
            <img className="w-full h-full" src={imageRootPath + props.imagePathName} alt={props.imagePathName}/>
        </div>
    );
}

export default BrandSliderItem;