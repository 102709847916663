import {childrenToArray} from "../../helpers/GlobalHelper";

export enum Padding {
    S = "S",
    M = "M",
    NONE = "NONE"
}

interface SectionProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    padding?: Padding;
    sectionId?:string;
}

const Section = (props: SectionProps) => {

    const getPadding = () => {
        switch (props.padding) {
            case Padding.S:
                return "lg:px-[8.75rem] px-[1rem]"
            case Padding.M:
                return "lg:px-[8.21rem] px-[1rem]"
            default:
                return ''

        }
    }

    const children = childrenToArray(props.children)

    return (
        <section className={(getPadding()) + ' w-100 ' + props.className} id={props.sectionId?props.sectionId:''}>
            {children.map(child => child)}
        </section>
    )

}

export default Section;