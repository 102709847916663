import React from "react";
import {getArrayChildByType} from "../../helpers/GlobalHelper";
import {OrganismComponent} from "../../enums/OrganismComponent";
import MultiProductBoxCategoryExpandingCardContainer from "./MultiProductBoxCategoryExpandingCardContainer";
import MultiProductBoxCategoryExpandingCard from "../organisms/MultiProductBoxCategoryExpandingCard";
import MultiProductBoxCategoryExpandingItem, {BgType} from "../molecules/MultiProductBoxCategoryExpandingItem";
import {Size, useIsWindowSizeAbove} from "../../hooks/useIsWindowSizeAbove";

interface MultiProductCardContainerProps {
    children: React.ReactNode[] | React.ReactNode
    imagePathNameForMobile?: string;
}


const MultiProductCardsContainer = (props: MultiProductCardContainerProps) => {

    const isAboveSm = useIsWindowSizeAbove(Size.sm);

    const cards = getArrayChildByType(OrganismComponent.MultiProductCard, props.children);

    return (
        <>
            {isAboveSm ? (<div
                    className="inline-grid md:grid-cols-3 grid-cols-1 gap-4">
                    {cards.map((card: any, index: number) => {
                        return card
                    })}
                </div>) :

                (<MultiProductBoxCategoryExpandingCardContainer>
                    <MultiProductBoxCategoryExpandingCard title={"MOCHILAS"} imagePathName={props.imagePathNameForMobile?props.imagePathNameForMobile : ''}>
                        <MultiProductBoxCategoryExpandingItem title={"Ver más"} bgType={BgType.Grey1}>
                            <div className="inline-grid grid-cols-1 gap-4">
                                {cards.map((card: any, index: number) => {
                                    return card;
                                })}
                            </div>
                        </MultiProductBoxCategoryExpandingItem>
                    </MultiProductBoxCategoryExpandingCard>
                </MultiProductBoxCategoryExpandingCardContainer>)
            }
        </>


    )

}

export default MultiProductCardsContainer;