
interface FooterProps {
    imagePath: string;
}

const Footer = (props: FooterProps) => {

    return (
        <a href="#home">
            <div className="flex flex-col space-y-2.5 text-left lg:text-center lg:justify-center lg:items-center w-full bg-red">
                <img src={props.imagePath} alt="" className="h-[18.688rem] md:max-w-[25rem] mx-auto"/>
            </div>
        </a>
    )

}

export default Footer;