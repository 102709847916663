import Thank from "./pages/ThankYou";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";

function CustomRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/thanks" element={<Thank/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default CustomRouter;