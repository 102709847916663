import Text, {TextType} from "../atoms/Text";
import {Color} from "../../enums/Color";
import {MoleculeComponent} from "../../enums/MoleculeComponent";



interface HeroBannerProps {
    title: string;
    imagePathName: string;
    textColor: Color;
}

const imageRootPath = '/images/hero/'

const HeroBanner = (props: HeroBannerProps) => {
    return (
        <div className="relative flex bg-cover bg-no-repeat block h-[15rem] md:h-[28rem] lg:bg-center " style={{backgroundImage: `url(${imageRootPath + props.imagePathName})`, backgroundPosition: 'bottom'}}>
            <div className={"absolute top-[15%] right-[50%] translate-x-1/2 "}>
                <Text type={TextType.H1}
                      color={props.textColor}
                      className="text-center">
                    {props.title}
                </Text>
            </div>
        </div>
    )
}

HeroBanner.componentName = MoleculeComponent.HeroBanner;
export default HeroBanner;