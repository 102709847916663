import {sendWhatsappEvent} from "./googleAnalytics";

const phoneNumber = "5491164629689"

export function sendWhatsappMessage(name, email, phone, message) {
    const text = `Nombre:${name} \n Email:${email} \n Teléfono:${phone} \n Mensaje:${message}`
    window.open(`https://wa.me/${phoneNumber}/?text=${textToEncode(text)}`)
}

export function sendWhatsappMessageCustomText(message) {
    sendWhatsappEvent("");
    window.open(`https://wa.me/${phoneNumber}/?text=${textToEncode(message)}`)
}

function textToEncode(text) {
    return encodeURIComponent(text)
}