import React from 'react';
import '../../App.css';
import MultiProductCard from "../organisms/MultiProductCard";
import MultiProductCardContainer from "../templates/MultiProductCardContainer";
import MultiProductBoxCategoryExpandingItem, {BgType} from "../molecules/MultiProductBoxCategoryExpandingItem";
import MultiProductBoxCategoryExpandingCard from "../organisms/MultiProductBoxCategoryExpandingCard";
import MultiProductBoxCategoryExpandingCardContainer from "../templates/MultiProductBoxCategoryExpandingCardContainer";
import Section, {Padding} from "../templates/Section";
import Banner1 from "../molecules/Banner1";
import BrandSlider from "../organisms/BrandSlider";
import BrandSliderItem from "../molecules/BrandSliderItem";
import '../../css/react-slider.css';
import Text, {TextType} from "../atoms/Text";
import {Color} from "../../enums/Color";
import Button from "../atoms/Button";
import Banner2 from "../molecules/Banner2";
import Banner3 from "../molecules/Banner3";
import HeroBanner from "../molecules/HeroBanner";
import ContactInput from "../atoms/ContactInput";
import ContactForm from "../organisms/ContactForm";
import GeneralLink, {LinkType} from "../atoms/GeneralLink";
import GeneralIcon, {IconType} from "../atoms/GeneralIcon";
import ContactLine from "../molecules/ContactLine";
import ContactFooter from "../organisms/ContactFooter";
import WhatsAppFloatingButton from "../atoms/WhatsAppFloatingButton";
import {sendEmail} from "../../services/emailJsService";
import HeroSlider from "../organisms/HeroSlider";
import HeroSliderItem, {TextAlignment} from "../molecules/HeroSliderItem";


function Home() {

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState('');

    const isInputValid = (input: string) => {
        return input != '';
    }

    const isValidForm = () => {
        return isInputValid(name) && isInputValid(email) && isInputValid(phone) && isInputValid(message);
    }

    return (

        <>

            {/*Main Slider*/}
            <Section padding={Padding.NONE} className=' lg:mt-[3.75rem] lg:min-h-[43.75rem] ' sectionId='home'>
                <HeroSlider>
                    <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                    title={''}
                                    imagePathName="heroBanner1.png"/>
                    <HeroSliderItem textColor={Color.White} textAlignment={TextAlignment.Center}
                                    title={'ENVIOS A \nTODO EL PAIS'} imagePathName="heroBanner2.png"/>
                    <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                    title={'VENTAS \nMAYORISTAS\n (Mínimo 200 kits)'} imagePathName="heroBanner3.png"/>
                </HeroSlider>
            </Section>

            <Section padding={Padding.S}
                     className='text-center my-[3.75rem] lg:min-h-[27.125rem] space-y-10 lg:pt-[3.75rem]'
                     sectionId='us'>
                <Text type={TextType.L} color={Color.Black} className=' text-justify '>
                    {'Somos una empresa especializada en la distribución de kits escolares y mochilas para gremios, sindicatos y empresas.\n' +
                        'Nuestros kits de primera calidad, están pensados para cubrir todas las etapas educativas y así proporcionar las herramientas adecuadas para fomentar la creatividad y el entusiasmo en estudiantes.\n' +
                        'Con envíos a todo el país, brindamos comodidad y confianza a quienes nos eligen año a año.\n' +
                        'Nuestra trayectoria de más de 20 años en el mercado, junto con nuestra atención personalizada, hablan de calidad y compromiso de parte de todo el equipo que conforma WASSA SRL.'}
                </Text>
                <Button text={"Contactanos"} onClick={() => {
                    return window.open('https://wa.me/01164629689 ', '_blank');
                }}/>
            </Section>

            {/*Banner Kits*/}
            <Section padding={Padding.NONE}>
                <Banner1 text="KITS ESCOLARES" imagePathName={'/images/banners/newBanner3.png'}/>
            </Section>
            {/*Combos*/}
            <Section padding={Padding.S} sectionId='kits' className='my-[1.875rem] lg:my-[3.125rem] space-y-12'>
                <HeroSlider>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares1/Slider1.png"/>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares1/Slider2.png"/>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares1/Slider3.png"/>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares1/Slider4.png"/>
                </HeroSlider>
                <HeroSlider>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares2/Slider1.png"/>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares2/Slider2.png"/>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares2/Slider3.png"/>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares2/Slider4.png"/>
                </HeroSlider>
                <HeroSlider>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares3/Slider1.png"/>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares3/Slider2.png"/>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares3/Slider3.png"/>
                        <HeroSliderItem textColor={Color.Blue} textAlignment={TextAlignment.Center}
                                        title={''}
                                        imagePathName="BannerKitEscolares3/Slider4.png"/>
                </HeroSlider>
            </Section>

            {/*Banner Mochilas*/}
            <Section padding={Padding.NONE} className='my-[1.875rem] lg:my-[3.125rem]'>
                <Banner3 text="MOCHILAS" imagePathName={'/images/banners/newBanner1.png'}/>
            </Section>

            {/*Mochilas*/}
            <Section padding={Padding.S} className="mt-4 md:mt-0 mb-[3.125rem]" sectionId='bags'>
                <MultiProductCardContainer imagePathNameForMobile="mochilas2.png">
                    <MultiProductCard imagesMapArray={[
                        ["slider6/Slider6_1.png", "Mochila 1"],
                        ["slider6/Slider6_2.png", "Mochila 2"],
                        ["slider6/Slider6_3.png", "Mochila 3"],
                        ["slider6/Slider6_4.png", "Mochila 4"]
                    ]} bagArticle={'18423'}/>
                    <MultiProductCard imagesMapArray={[
                        ["slider4/Slider4_1.png", "Mochila 1"],
                        ["slider4/Slider4_2.png", "Mochila 2"],
                        ["slider4/Slider4_3.png", "Mochila 3"],
                        ["slider4/Slider4_4.png", "Mochila 4"]
                    ]} bagArticle={'23233'}/>
                    <MultiProductCard imagesMapArray={[
                        ["slider2/Slider2_1.png", "Mochila 1"],
                        ["slider2/Slider2_2.png", "Mochila 2"],
                        ["slider2/Slider2_3.png", "Mochila 3"],
                        ["slider2/Slider2_4.png", "Mochila 4"]
                    ]} bagArticle={'23994'}/>
                    <MultiProductCard imagesMapArray={[
                        ["slider1/Slider1_1.png", "Mochila Id 45454"],
                        ["slider1/Slider1_2.png", "Mochila 2"],
                        ["slider1/Slider1_3.png", "Mochila 3"],
                        ["slider1/Slider1_4.png", "Mochila 4"]
                    ]} bagArticle={'23995'}/>
                    <MultiProductCard imagesMapArray={[
                        ["art.16761/art.16761-1.png", "Mochila 1"],
                        ["art.16761/art.16761-2.png", "Mochila 2"],
                        ["art.16761/art.16761-3.png", "Mochila 3"],
                        ["art.16761/art.16761-4.png", "Mochila 4"],
                    ]} bagArticle={'16761'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/art.17017.png", "Mochila 1"]
                    ]} bagArticle={'17017'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/art.18429.png", "Mochila 1"]
                    ]} bagArticle={'18429'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/Individuales_5.png", "Mochila 1"]
                    ]} bagArticle={'18430'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/art.23230.png", "Mochila 1"]
                    ]} bagArticle={'23230'}/>
                    <MultiProductCard imagesMapArray={[
                        ["art.25112/art.25112-1.png", "Mochila 1"],
                        ["art.25112/art.25112-2.png", "Mochila 2"],
                        ["art.25112/art.25112-3.png", "Mochila 3"],
                        ["art.25112/art.25112-4.png", "Mochila 4"],
                    ]} bagArticle={'25112'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/art.25113.png", "Mochila 1"]
                    ]} bagArticle={'25113'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/art.25114.png", "Mochila 1"]
                    ]} bagArticle={'25114'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/art.25116.png", "Mochila 1"]
                    ]} bagArticle={'25116'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/art.25646.png", "Mochila 1"]
                    ]} bagArticle={'25646'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/art.25651.png", "Mochila 1"]
                    ]} bagArticle={'25651'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/art.25806.png", "Mochila 1"]
                    ]} bagArticle={'25806'}/>
                    <MultiProductCard imagesMapArray={[
                        ["individuales/Individuales_8.png", "Mochila 1"]
                    ]} bagArticle={''}/>

                </MultiProductCardContainer>
                <Button className="hidden md:inline mt-[3.75rem] mb-[1.25rem] "
                        text={"¡Contactános y te enviamos el catálogo completo de nuestros productos!"}
                        onClick={() => {
                            return null
                        }}/>
            </Section>

            {/*BANNER 2*/}
            <Section padding={Padding.NONE}>
                <Banner2 text={'¡PEDÍ TU\nPRESUPUESTO!'} imagePathName={'newBanner2.png'}/>
            </Section>


            <Section padding={Padding.M} className='mt-[4.375rem]' sectionId='contact'>
                <Text className="text-center mb-[1.875rem]" type={TextType.XL} color={Color.Black}>
                    ATENCIÓN PERSONALIZADA
                </Text>
                <Button text={"Contactanos"} onClick={() => {
                   return window.open('https://wa.me/01164629689 ', '_blank');
                }}/>

                <ContactForm>
                    <ContactInput onChangeFunction={setName} isRequiered>
                        Nombre y apellido
                    </ContactInput>
                    <ContactInput onChangeFunction={setEmail} isRequiered>
                        Email
                    </ContactInput>
                    <ContactInput onChangeFunction={setPhone} isRequiered>
                        Teléfono
                    </ContactInput>
                    <ContactInput onChangeFunction={setMessage} isBig isRequiered>
                        Mensaje
                    </ContactInput>
                    <Button className={isValidForm() ? ' cursor-pointer ' : ' cursor-not-allowed opacity-25 pointer-events-none'} text={"Enviar"} onClick={() => {
                        sendEmail(name, email, phone, message).then((response) => {
                            if (response.status === 200) {
                                setName('');
                                setEmail('');
                                setPhone('');
                                setMessage('');
                                setTimeout(function () {
                                    window.location.replace('/thanks');
                                } );

                            } else {
                                alert("Hubo un error al enviar el mensaje");
                            }
                        });
                    }}/>
                </ContactForm>

            </Section>


            <Section padding={Padding.S} className='my-[1.875rem] lg:my-[3.125rem]'>
                <BrandSlider>
                    <BrandSliderItem imagePathName={'brand1.png'}/>
                    <BrandSliderItem imagePathName={'brand2.png'}/>
                    <BrandSliderItem imagePathName={'brand3.png'}/>
                    <BrandSliderItem imagePathName={'brand4.png'}/>
                    <BrandSliderItem imagePathName={'brand5.png'}/>
                    <BrandSliderItem imagePathName={'brand6.png'}/>
                    <BrandSliderItem imagePathName={'brand7.png'}/>
                </BrandSlider>
            </Section>


            <Section padding={Padding.NONE}>
                <HeroBanner title={'ENVIAMOS \nA TODO EL PAIS'} imagePathName="heroBanner.png"
                            textColor={Color.Blue2}/>
            </Section>
            <Section padding={Padding.S} className=' my-[1.875rem] lg:my-[3.125rem]'>
                <ContactFooter>
                    <Text type={TextType.L} color={Color.Black}>
                        {'¡Estamos listos para ayudarte! '}
                    </Text>
                    <ContactLine>
                        <GeneralIcon color='#FFFFFF' type={IconType.Whastapp} url='tel:+11 2568-5414' isExternal/>
                        <GeneralLink type={LinkType.Contact} isExternal={false} path='tel:+11 6462-9689'>11
                            6462-9689</GeneralLink>
                    </ContactLine>
                    <ContactLine>
                        <GeneralIcon color='#FFFFFF' type={IconType.Mail} url='mailto:kitsescolaresn1@gmail.com'
                                     isExternal/>
                        <GeneralLink type={LinkType.Contact} isExternal={false}
                                     path='mailto:kitsescolaresn1@gmail.com'>kitsescolaresn1@gmail.com</GeneralLink>
                    </ContactLine>
                </ContactFooter>
            </Section>
            <WhatsAppFloatingButton/>
        </>
    );
}

export default Home;
