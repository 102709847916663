import {AtomComponent} from "../../enums/AtomComponent";
import {Color} from "../../enums/Color";

enum TextType {
    S = "S",
    M = "M",
    M_20 = "S-20",
    L = "L",
    XL = "XL",
    H1 = "H1",
    H2 = "H2",
    H3 = "H3",
    H4 = "H4"
}

const getTextStyle = (type: TextType) => {
    switch (type) {
        case TextType.S:
            return " text-s ";
        case TextType.M:
            return " text-m ";
        case TextType.M_20:
            return " text-m-20 ";
        case TextType.L:
            return " text-l ";
        case TextType.XL:
            return " text-xl ";
        case TextType.H1:
            return " text-h1 ";
        case TextType.H2:
            return " text-h2 ";
        case TextType.H3:
            return " text-h3 ";
        case TextType.H4:
            return " text-h4 ";
        default:
            return " text-m ";
    }
}

interface TextProps {
    type: TextType;
    color: Color;
    children: string | undefined ;
    className?: string;
}

function Text(props: TextProps) {

    let textClasses = '';
    textClasses = textClasses.concat(
        getTextSizeAndFont(),
        getColor(),
        '  ',
        props.className ? props.className : ''
    );

    function getTextSizeAndFont() {
        switch (props.type) {
            case TextType.H1:
                return " text-h3 lg:text-h1 font-josefin-sans-bold-italic font-bold italic"
            case TextType.H2:
                return " text-h2 font-archivo-black-regular"
            case TextType.H3:
                return " text-h3 font-josefin-sans-bold-italic font-bold italic"
            case TextType.H4:
                return " text-h4 font-archivo-black-regular "
            case TextType.S:
                return " text-s font-dm-sans font-semibold "
            case TextType.M:
                return " text-m font-dm-sansfont-bold "
            case TextType.M_20:
                return " text-m-20 font-dm-sans font-semibold "
            case TextType.L:
                return "text-s lg:text-l font-dm-sans font-medium"
            case TextType.XL:
                return " text-m-20 lg:text-xl font-dm-sans font-bold "
            default:
                return '';
        }
    }

    function getColor() {
        switch (props.color) {
            case Color.White:
                return " text-white "
            case Color.Black:
                return " text-black "
            case Color.Blue:
                return " text-blue "
            case Color.Blue2:
                return " text-button-blue "
            case Color.Red:
                return " text-red "
            default:
                return '';
        }
    }


    function getChildren() {
        if (typeof props.children !== 'string') {
            console.error('Children must be a string');
            return '';
        } else {
            return props.children;
        }
    }

    return (
        <p style={{ whiteSpace: 'pre-wrap' }} className={textClasses}>{getChildren()}</p>
    );
}

Text.componentName = AtomComponent.Text;

export {TextType};

export default Text;