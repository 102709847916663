import Text, {TextType} from "../atoms/Text";
import {Color} from "../../enums/Color";

interface Banner3Props {
    text: string;
    imagePathName: string;
}

const Banner3 = (props: Banner3Props) => {

    return (
        <div className="hidden md:flex w-full h-[8.625rem] md:h-[27.5rem] bg-no-repeat bg-bottom bg-center" style={{'backgroundImage':'url(images/banners/newBanner1.png)'}}>
            <div className="w-2/6 bg-contain bg-no-repeat bg-centerblock bg-center" />
            <div className="w-4/6 items-center justify-center flex uppercase">
            </div>       
        </div>
    )

}

export default Banner3;