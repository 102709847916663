
interface ContactFooterProps {
    children: React.ReactNode[] | React.ReactNode;
}

const ContactFooter = (props: ContactFooterProps) => {

    return (
        <div className="flex flex-col space-y-2.5 text-left lg:text-center lg:justify-center lg:items-center">
            {props.children}
        </div>
    )

}

export default ContactFooter;