import Text, {TextType} from "../atoms/Text";
import {Color} from "../../enums/Color";

interface Banner2Props {
    text: string;
    imagePathName: string;
}

const Banner2 = (props: Banner2Props) => {

    return (
        <div className="flex w-full h-[8.625rem] md:h-[27.5rem]">
            <div className="w-4/6 bg-white items-center justify-center flex">
                <Text type={TextType.H1} color={Color.Red} className=" text-center ">
                    {props.text}
                </Text>
            </div>
            <div className="w-2/6 bg-contain bg-no-repeat bg-centerblock" style={{'backgroundImage' : 'url(/images/banners/newBanner2.png)'}}/>
        </div>
    )

}

export default Banner2;