import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, {useState} from "react";
import Slider from 'react-slick';
import {getArrayChildByType} from "../../helpers/GlobalHelper";
import {OrganismComponent} from "../../enums/OrganismComponent";
import {MoleculeComponent} from "../../enums/MoleculeComponent";
import { ReactComponent as SliderRightArrow } from '../../assets/SliderRightArrow.svg';
import { ReactComponent as SliderLeftArrow } from '../../assets/SliderLeftArrow.svg';
import '../../css/react-slider.css';


interface HeroSliderProps {
    children: React.ReactNode[] | React.ReactNode
}

function HeroSlider(props: HeroSliderProps) {

    const childrenArray = getArrayChildByType(MoleculeComponent.HeroSliderItem , props.children);

    const [currentSlide, setCurrentSlide] = useState(0);

    const ArrowRight = (props:any) => (
        <button
            {...props} className="slick-next">
                <SliderRightArrow/>
        </button>
    );
    const ArrowLeft = (props:any) => (
        <button
            {...props} className="slick-prev">
                <SliderLeftArrow/>
        </button>
    );

    const settings = {
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        nextArrow: <ArrowRight/>,
        prevArrow: <ArrowLeft/>,
        appendDots: (dots: React.ReactNode) => (
            <div style={{ position: "absolute", bottom: "10%", width: "100%" }}>
              <ul style={{ margin: "0px", display: "flex", justifyContent: "center" }}>{dots}</ul>
            </div>
          ),
    };

    const getImages = () => {
        return childrenArray.map((child, i) => {
            return child;
        })
    }

    return (
        <div className="relative">
            <Slider className="w-full" {...settings}>
                {getImages()}
            </Slider>
        </div>
    );

}

HeroSlider.componentName = OrganismComponent.HeroSlider
export default HeroSlider;